import React, {Component}            from 'react'
import {Link}                        from 'react-router'
import cn                            from 'classnames'
import marked                        from 'marked'
import VimeoPlayer                   from '../components/vimeo-player'
import VimeoHelper                   from '../util/vimeo-helper'
import getZoomPercentage from '../util/getZoomPercentage'
import EventBusPublisher from "./event-bus-publisher";
import {isInMobileApp} from "../platform";

const imgSizes = {
    left: '475',
    center: '475',
    right: '475'
}

function externalizeHrefs(el) {
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

function getExcerptImages(data) {
    const {id, column, vimeoId, page} = data
    const _page = page.replaceAll("page-", "")
    const link = `/news/${_page}/article?id=${encodeURIComponent(id)}`
    if (vimeoId) {
        return <div className="video-container">
                <VimeoPlayer videoId={parseInt(vimeoId, 10)} />
            </div>
    } else if (data.type === 'two-image') {
        const url0 = `url(${data.images[0]})`
        const url1 = `url(${data.images[1]})`
        return (
            <div className="images two-image">
              <Link to={link}><div className="image left"  style={{backgroundImage:url0}} /></Link>
              <Link to={link}><div className="image right" style={{backgroundImage:url1}} /></Link>
              <div className="clearfix" />
            </div>
        )
    } else if (data.image1) {
        const imageWidth = imgSizes[column];
        const url = `${data.image1}`
        return (
            <div className="images one-image">
              <img src={url} onClick={() => EventBusPublisher.parentRedirect(link)}/>
              <div className="clearfix" />
              <div className="credits">{data.credits}</div>
            </div>
        )
    } else {
        return null
    }
}

class Excerpt extends Component {
    constructor(props) {
        super(props);
        this.vimeoVideos = null;
        this.onResize = this.onResize.bind(this);
        this.getFontSizeBasedOnZoomIn = this.getFontSizeBasedOnZoomIn.bind(this);
        this.onClickAnchorElement = this.onClickAnchorElement.bind(this)
        this.handleOnClickExcerpt = this.handleOnClickExcerpt.bind(this);
        this.addEventListeners = this.addEventListeners.bind(this);
        this.state = {
            excerptContentFontSize: 'excerpt-content-normal-fz'
        }
    }

    getFontSizeBasedOnZoomIn() {
        let zoom = getZoomPercentage();
        let fontSize = 'excerpt-content-normal-fz';
        if(zoom > 100 && zoom <= 110) {
            fontSize = 'excerpt-content-normal-fz2';
        } else if(zoom >= 110 && zoom <= 125) {
            fontSize = 'excerpt-content-normal-fz3';
        } else if(zoom > 125 && zoom <= 150) {
            fontSize = 'excerpt-content-big-fz';
        } else if (zoom > 150) {
            fontSize = 'excerpt-content-big-fz2';
        }
        return fontSize;
    }

    onResize(){
        let excerptContentFontSize = this.getFontSizeBasedOnZoomIn();
        this.setState({excerptContentFontSize});
    }

    onClickAnchorElement(event) {
        event.preventDefault();
        event.stopPropagation();

        let element = event.target;
        if (element.tagName === 'IMG') {
            element = element.parentElement;
        }
        let url = element.getAttribute('href');
        if (!url) {
            return;
        }
        const isInternalLink = location.hostname === element.hostname || !element.hostname || element.hostname === "neilyoungarchives.com";
        if (isInternalLink) {
            return EventBusPublisher.parentRedirect(url)
        } else {
            return EventBusPublisher.openExternalLink(url);
        }
    }

    addEventListeners() {
        const anchorElements = document.querySelectorAll(".excerpt a");
        anchorElements.forEach(anchorElement => {
            if (!anchorElement.dataset.listenerAdded) {
                anchorElement.addEventListener("click", this.onClickAnchorElement, { once: true });
                anchorElement.dataset.listenerAdded = "true";
            }
        });
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize, false);
        externalizeHrefs(this.refs.content);
        this.onResize();
        this.addEventListeners();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.addEventListeners();
    }


    bindVideoEvents() {
        const container = this.refs.content;
        const iframes = container.querySelectorAll("iframe");
        this.vimeoVideos = new VimeoHelper(iframes);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false);
        this.vimeoVideos && this.vimeoVideos.destroy();
    }

    handleOnClickExcerpt (id) {
        if (!isInMobileApp)
            return;
        this.props.link(encodeURIComponent(id));
    }

    render(){
        const { headline, type, id, dateText, column, headlineText, bodyText, subtitle, callout, showBodyText, titlesFullWidth, titleDivider, topTitleDivider, subtitleDivider, calloutDivider, byline ,articleLayout, excerpt} = this.props.data
        const { idx, page, layout, pageStyle } = this.props;

        // Default page rules
        let featuredPost = (column === 'center') && idx === 0;
        const firstPost = idx === 0;
        const centerColumn = column === 'center';
        let showContent = (firstPost || showBodyText )&& (excerpt !== '&nbsp;');
        let showTopDivider = false;
        let imageAtTop = false;
        let includeDate = true;
        let includeByline = false;

        // Page 2 rules
        if ( pageStyle === 'viewpoint') {
            showTopDivider = column === 'left' && !firstPost
            showContent = showContent || column === 'left'
            featuredPost = column === 'center' && !titlesFullWidth  // This is because page2/center is featured layout when titles full width is not selected.
        }

        // Page 4 rules
        if ( pageStyle === 'subscribers-news') {
            showContent = showContent || column === 'left'
        }

        // Page 6 rules
        if (pageStyle === 'earth-news') {
            imageAtTop = featuredPost;
            showTopDivider = centerColumn;
            showContent = showBodyText || column === 'left' || column === 'right';
            includeByline = true;
        }

        // Page 7 rules
        if (pageStyle === 'inside-nya') {
            includeDate = false;
            featuredPost = false;
        }
        if(pageStyle == 'listen'){
            showTopDivider = column === 'left' || column === 'right'
        }
        //change this when page migration is done
        if(pageStyle == 'politics'){
            showTopDivider = column === 'center' || column === 'left' || column === 'right' ;
        }

        /* Two column layout if
            It is the center column
            It is the first post or the option showBodyText is true, this prevents posts that don't show body text to being two column
            If titles full width is intended the two columns layout are applied just to excerpt content
            If layout is 3-column
        */
       let twoColumnTitle = centerColumn && showContent && !titlesFullWidth && (layout === 'column-3')
       let twoColumnContent = titlesFullWidth && (layout === 'column-3')
       let singleColumn = centerColumn && articleLayout !== undefined && (articleLayout == 'single-column')
       let threeColumnTitle = centerColumn && showContent && !titlesFullWidth && (layout === 'column-3-full')
       let threeColumnContent = titlesFullWidth && (layout === 'column-3-full')
        let ct = cn('title-column', {'double-column': twoColumnTitle}, {'three-column': threeColumnTitle},{'single-format':singleColumn})
        let cx = cn('excerpt-content', type, {'double-column': twoColumnContent}, {'three-column': threeColumnContent},{'single-format':singleColumn},`${this.state.excerptContentFontSize}`)
        return (
            <div className="excerpt" onClick={()=>this.handleOnClickExcerpt(id)}>
                { (featuredPost && imageAtTop) && getExcerptImages(this.props.data)}
                { headlineText &&
                    <div onClick={()=>this.props.link(encodeURIComponent(id))}>
                        { showTopDivider && <div style={{width: `${topTitleDivider || 150}px`}} className="excerpt-divider" /> }
                        <div className={'title headline ' + column + ` ${pageStyle}`} ref="content" dangerouslySetInnerHTML={{__html:marked(headlineText || '')}} />
                        { titleDivider !== 0 && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider" /> }
                    </div>
                }
                { (headline && !headlineText) &&
                    <div onClick={()=>this.props.link(encodeURIComponent(id))}>
                        { showTopDivider && <div className="excerpt-divider" /> }
                        <div className={'title headline ' + column + ` ${pageStyle}`}><h1>{headline}</h1></div>
                        { titleDivider !== 0 && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider" /> }
                    </div>
                }
                { (featuredPost && !imageAtTop) && getExcerptImages(this.props.data)}
                <div className={ct} >
                    {/* centerColumn && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider notop" /> */}
                    { subtitle &&
                        <div onClick={()=>this.props.link(encodeURIComponent(id))}>
                            <div className={'title subtitle ' + column + ` ${pageStyle}`} ref="content" dangerouslySetInnerHTML={{__html:marked(subtitle || '')}} />
                            { subtitleDivider !== 0 && <div style={{width: `${subtitleDivider || 150}px`}} className="excerpt-divider" /> }
                        </div>
                    }
                    { callout &&
                        <div onClick={()=>this.props.link(encodeURIComponent(id))}>
                            <div className={'title callout ' + column + ` ${pageStyle}`} ref="content" dangerouslySetInnerHTML={{__html:marked(callout || '')}} />
                            { calloutDivider !== 0 && <div style={{width: `${calloutDivider || 150}px`}} className="excerpt-divider" /> }
                        </div>
                    }
                    { (includeByline && byline) &&
                        <div onClick={()=>this.props.link(encodeURIComponent(id))}>
                            <div className="byline">{byline}</div>
                        </div>
                    }
                    { (!featuredPost && showContent) && getExcerptImages(this.props.data)}
                    { includeDate && <div className="dateline">{dateText}</div> }
                    { <div className={cx} ref="content" dangerouslySetInnerHTML={{__html: showContent ? marked(excerpt) : ''}} />}
                </div>
            </div>
        )
    }
}

export default Excerpt
